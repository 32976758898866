export const CONFIG = {
  logo: "/logo.png",
  site_title: "BlueShield Management",
  Auth0Domain: "bluesheildmanagement.us.auth0.com",
  Auth0ClientId: "5ywsobO9wOrf8rKS6lRFJklkKlcXdJHX",
  Auth0Audience: "https://bluesheildmanagement-api.trackpac.io",
  API_URL: "https://blueshieldmanagement-api.trackpac.io/",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/clkha3odl008i01qpeqws6d21",
  websiteURL: "https://blueshieldmanagement.trackpac.io",
  billingEnabled: false,
  deviceCost: 15,
  primaryColour: "#004AAD",
  secondaryColour: "#0d00ad",
};
